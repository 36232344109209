import React, {Component} from 'react'



class ContactForm extends Component {
    constructor(props) {
      super(props);
      this.state = { name: '',
      done:null};
    }



    handleChange = (event) => {
      this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit = (event) => {
// submit form to Cloud Function
	//    alert('A form was submitted: ' + this.state);
    // 
	    var url = 'https://us-central1-contact-form-314717.cloudfunctions.net/saveContactInfo'
	    fetch(url, {
	        method: 'POST',
            body: JSON.stringify(this.state)
	      }).then(function(response) {
			  console.log(response.text());
//			//  alert(response.json);
//	        console.log("Response=" + response.json())
////        return response.json();
	      }).catch(function(err) {
	      console.log('Fetch Error :-S', err);
	    });
		this.setState({done: true})
event.preventDefault();
    }
	

	
  render() {
	
	  
    return (
		<>
		{!this.state.done && (
			
<form onSubmit={this.handleSubmit}>
  <label>Your Name *</label>
  <input  type="text" className="form-control" required value={this.state.value} name="name" onChange={this.handleChange} />
  
  <label>Your Email*</label>
  <input  type="text" className="form-control" required value={this.state.value} name="email" onChange={this.handleChange} />
  
  <label>How can we help?</label>
  <textarea className="form-control" value={this.state.value} name="message" onChange={this.handleChange} >
  </textarea>
  <br/>
  <button type="submit" value="Submit" className="btn btn-primary btn-lg btn-loading" data-loading-text="Sending" style={{float: "right"}}>
    Submit
  </button>
</form >
	
		)}
		
		{this.state.done && (
			<div className="text-center" style={{paddingTop: "6em"}}>
			<h1>Thanks {this.state.name}!</h1>
			<h3>We&#39;ll get back to you within the next few business days.</h3>
			</div>
		)}
</>
    )
  }
}

export default ContactForm
