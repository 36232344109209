import React, {Component} from 'react'
import ContactForm from './ContactForm'


class Contact extends Component {
   
	

	
  render() {
	
	  
    return (
<section className="bg-primary-alt  o-hidden nomargin pt-4" style={{paddingTop: "2em", minHeight: "577px"}} id="contact">
			<div className="container">
			<div className="text-center" >
              <h2 id="" className="h1">Send us a message</h2>
              <p className="lead">
			      It&#39;s never too early to chat
              </p>
			</div>
 <div className="row justify-content-center">
 <div className="col-12 col-md-7">
		<ContactForm />
			</div>
			</div>
			</div>
</section>
    )
  }
}

export default Contact
