import React, {Component} from 'react'
import ClientLogos from '../ClientLogos'
import Contact from '../Contact'
import PrimaryExample from '../PrimaryExample'
import SecondExample from '../SecondExample'
import Advantage from '../partials/Advantage'
import ServiceList from '../partials/ServiceList'


class Home extends Component {
  render() {
    return (
		<div>
		  <section className="bg-primary text-light o-hidden" style={{ 'marginBottom': '2em' }}>
		    <div className="container">
		      <div className="row align-items-center min-vh-50">
		        <div className="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
		          <h1 className="display-3" style={{'textTransform': "uppercase"}}>Videos designed for results.</h1>
		          <div className="mb-4" >
		            <p className="lead">Animation, ad concepting, and digital content built to drive real world results. </p>
		          </div>
		          <a href="#contact" className="btn btn-lg btn-white">Get in Touch</a>
		        </div>
		        <div className="col-lg-6 text-center d-none d-lg-block  " >
		          <img src="/images/phone-outline.png" style={{'maxHeight':"350px", "top": "20px","position": "relative"}}  />
		     
		         
				</div>
		      </div>
		    </div>
		  </section>

    <div className="row justify-content-center text-center mb-6">
      <div className="col-xl-8 col-lg-9">
          <ClientLogos />	
      </div>
    </div>
		
		<PrimaryExample />
		
		<SecondExample />
			
		<Contact />
		</div>
    )
  }
}

export default Home
