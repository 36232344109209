import React from 'react'

function ContactBar() {
  return ( 
	
    <section className="pt-0">
      <div className="container">
        <div className="row text-center">
          <div className="col-sm-4 mb-3 mb-sm-0">
            <h3 className="h2">Visit</h3>
            <span className="lead">
Studio visits closed due to Covid-19
            </span>
          </div>
          <div className="col-sm-4 mb-3 mb-sm-0">
            <h3 className="h2">Email</h3>
            <a href="#" className="lead">hello@godalphul.com</a>
          </div>
          <div className="col-sm-4 mb-3 mb-sm-0">
            <h3 className="h2">Call</h3>
            <span className="lead">
              615-696-9370
            </span>
            <div className="text-small text-muted">Mon - Fri, 9am - 5pm</div>
          </div>
        </div>
      </div>
    </section>
	  
      );
}

export default ContactBar
