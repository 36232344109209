import React, {Component} from 'react'

class Table extends Component {
  render() {
    return (
	<nav className="navbar bg-primary navbar-expand-lg navbar-dark fixed-top">
	  <div className="container">
	    <a className="navbar-brand fade-page" href="/">Godalphul Media</a>
		  

		
	    <a href="#contact" className="btn btn-white ml-lg-3">Contact</a>
	  </div>
	</nav>
    )
  }
}

export default Table