import React from 'react'

function ServiceList() {
  return ( 
	
<section className="mt-0">
      <div className="container">
        <div className="row justify-content-center text-center mb-2">
          <div className="col-7">
            <h2 className="display-4 mx-xl-6">The Work We Do</h2>
            <p className="lead">
 We&#39;re a full service animation studio. Not exactly sure what you need? Just <a href="contact"> reach out</a> and we can help figure that out.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-3 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <div className="card card-icon-2 card-body justify-content-between hover-shadow-3d">
              <div className="icon-round mb-3 mb-md-4 icon bg-primary">
                <img className="icon bg-primary" src="" alt="icon" data-inject-svg=""/>
              </div>
              <h5 className="mb-0">Animated Videos</h5>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
            <div className="card card-icon-2 card-body justify-content-between hover-shadow-3d bg-primary text-light">
              <div className="icon-round mb-3 mb-md-4 icon bg-white">
                <img className="icon bg-white" src="" alt="icon" data-inject-svg=""/>
              </div>
              <h5 className="mb-0">Social Media Content</h5>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
            <div href="#" className="card card-icon-2 card-body justify-content-between hover-shadow-3d bg-primary-2 text-light">
              <div className="icon-round mb-3 mb-md-4 icon bg-white">
                <img className="icon bg-white" src="" alt="icon" data-inject-svg=""/>
              </div>
              <h5 className="mb-0">Promo Videos</h5>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
            <div href="#" className="card card-icon-2 card-body justify-content-between hover-shadow-3d bg-primary-3 text-light">
              <div className="icon-round mb-3 mb-md-4 icon bg-white">
                <img className="icon bg-white" src="" alt="icon" data-inject-svg=""/>
              </div>
              <h5 className="mb-0">Explainer Videos</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
      );
}

export default ServiceList
