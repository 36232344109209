import React from 'react'
import Plyr from 'plyr-react'
import ImgWithWebp from './ImgWithWebp'
import 'plyr-react/dist/plyr.css'

const videoSrc = {
  type: "video",
  sources: [
    {
      src: "L2rdLX2vd5U",
      provider: "youtube"
    }
  ]
};

const plyrOptions = {
  autoplay:false, clickToPlay: false, muted:true
};

function PrimaryExample() {
  return ( 
	
	
<section className="bg-primary-2-alt o-hidden py-4">

      <div className="container">

	  
        <div className="row align-items-center justify-content-around">
          <div className="col-md-5 pr-xl-4 mb-4 mb-md-0">
            <h3 className="h1 text-primary-2">
              We make videos to help you meet your goals.
            </h3>
            <p className="lead">Whether you want to increase app downloads, boost sales, or even celebrate a WWE wrestler&#39;s retirement, we can help.</p>
          
          </div>
          <div className="col-xl-5 col-md-6 aos-init aos-animate" data-aos="fade-left">
        
 <div className="rounded o-hidden">		            
<Plyr source={videoSrc} options={plyrOptions}/>
	</div>		
	  <div className="card mt-2 card-notes">
	    <div className="card-body p-2">
	      <div className="row">
	        <div className="col-3">
	          <i>Made for</i> 
	          <ImgWithWebp src="/images/client-logos/wwe-logo-grey-small.png" style={{"maxHeight": "50px"}}/>
	        </div>
	        <div className="col-9">
	          <b>Goal:</b> Highlight moments in the Undertaker&#39;s career and drive interest around his retirement. <br/>
	          <b>Styles:</b> DYI, Papercraft, Stop Motion, Animation 
	        </div>	  
	      </div>
	    </div>	
	  </div>		
          </div>
        </div>

      </div>
    </section>

  
	
      );
}

export default PrimaryExample
