//import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap/dist/js/bootstrap.bundle.min'
//import './assets/godalphul.css'
 import './assets/scss/theme.scss';

import React, {Component} from 'react'
import Navigation from './components/Navigation'
import Home from './components/pages/Home'
//import About from './components/pages/About'
//import Services from './components/pages/Services'
//import Service from './components/pages/Service'
//import Work from './components/pages/Work'
import Contact from './components/pages/Contact'
//import Careers from './components/pages/Careers'
//import Opening from './components/pages/Opening'
import Footer from './components/Footer'
 import AOS from 'aos';
 import 'aos/dist/aos.css';
import ReactGA from 'react-ga';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Helmet} from "react-helmet";


class App extends Component {

	componentDidMount() {
	  // or simply just AOS.init();
	  AOS.init({
	    // initialise with other settings
	  //  duration : 2000
	  });
	  ReactGA.initialize('UA-118197487-1');

	}
		  
  render() {
	  ReactGA.pageview(window.location.pathname + window.location.search);
    return (
	  <Router>
      <div className="App">
    <Helmet>
		<title> Godalphul Media | A Content Creation & Video Production Company </title>
        <link rel="canonical" href="http://godalphul.com/" />
		
    </Helmet>
		<div>
    <div className="loader">
      <div className="loading-animation"></div>
    </div>
		  <Navigation />
		  <Switch>
		  <Route path="/" exact component={Home} />
		  <Route path="/contact"  component={Contact} />		
		 			
		  </Switch>
		  <Footer />
		</div>
      </div>
		

		
		
      </Router>
    )
  }
}

export default App