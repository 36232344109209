import React from 'react'


function SecondExample() {
  return ( 

	  
<section className="  o-hidden p-0 py-4" >
      <div className="container">
        <div className="row align-items-center justify-content-around">
	    <div className="col-10 aos-init aos-animate" >
          <div className=" card rounded o-hidden text-center shadow-3d" data-aos="slide-right" data-aos-delay="300">	
            <div className="card-body " >
	        <div className="row justify-content-center ">
	  
              <div className="col-10 col-md-5  aos-init aos-animate text-center" >
                <img src="/images/motion-graphics.gif" style={{'maxHeight':"350px", 'border':"1px solid #eaeaea"}} className="rounded o-hidden" />
              </div>
	  
              <div className="col-md-6 offset-1 pr-xl-4  mb-md-0 pt-5 text-left">
                <h3 className="h1 text-primary-3 mb-0">
                  You&#39;re unique.
                </h3>
                <h6 className="text-primary">
                  Why look like everyone else?
                </h6>
                <p className="lead">
Your fans should see your videos and instantly know it&#39;s you. Every video we make is unique and meticulously crafted to be an extension of the client.</p>
             </div>
	  
	  	    </div>
            </div>
          </div>
	  </div>
        </div>
      </div>
</section>
	  
	
      );
}

export default SecondExample
