import React, {Component} from 'react'
import {Helmet} from "react-helmet";
import ContactForm from '../ContactForm'
import ImgWithWebp from '../ImgWithWebp'
import ContactBar from '../ContactBar'

class Contact extends Component {
  render() {
    return (
		<>
      <Helmet>
        <title> Contact | Godalphul Media </title>
      </Helmet>
	
	

	
	
    <section className="header-inner position-relative mb-0 pb-0">
     
      <div className="container py-0">
     
        <div className="row justify-content-center" data-aos="fade-right">
	
         
		
          <div className="col-sm-12 col-md-7">
		     <div className="card">
		     <div className="card-body">
            <h1 >Let&#39;s Chat</h1>
            <p className="lead mb-0">Whether you know exactly what you want or you&#39;re just browsing, we&#39;d love to learn more about your organization.  </p>
            <ContactForm/>
               </div>
               </div>
          </div>
 
      
		
		
        </div>
		
      </div>
    </section>

<ContactBar/>


		</>
    )
  }
}

export default Contact
