import React from 'react'

const ImgWithWebp = ({
  src,
  webpSrc = src.replace(/\.[^/.]+$/, ".webp"),
  type = 'image/webp',
  ...delegated
}) => {
  return (
	 
//	  {src}
//	  {...delegated}
	  
    <picture>
      <source srcSet={webpSrc} type={type} />
      <img src={src} {...delegated} />
    </picture>

  );
};


export default ImgWithWebp