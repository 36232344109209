import React from 'react'
import ImgWithFallback from './ImgWithFallback'
import ImgWithWebp from './ImgWithWebp'

function ClientLogos() {
  return ( 
	<section className="p-0 m-0">
	   <div className="text-center text-label"> We&#39;ve Worked With</div>
       <div className="row justify-content-center text-center mt-3">  
	 
         <div className="col-4 col-md-2 " >
	       <ImgWithWebp src="/images/client-logos/splendid-spoon-logo-small.png" alt="SplendidSpoon Logo" className="img-fluid"/>
         </div>  
	  
         <div className="col-4 col-md-2 " >
           <ImgWithWebp src="/images/client-logos/her-logo-small.png" alt="HER logo" className="img-fluid"/>
         </div>
	  
         <div className="d-none d-md-block col-4 col-md-2" >
           <ImgWithWebp src="/images/client-logos/wwe-logo-small.png" alt="WWE logo" className="img-fluid"/>
         </div>
	  
	  
         <div className="col-4 col-md-2 " >
           <ImgWithWebp src="/images/client-logos/usa-network-logo-small.png" alt="USA Network logo" className="img-fluid"/>
         </div>
	  
        <div className="d-none d-md-block col-4 col-md-2" >
           <ImgWithWebp src="/images/client-logos/winn-dixie-logo-small.png" alt="winn dixie logo" className="img-fluid"/>
         </div>	  

	  
	  
   
       </div>
	  </section>
      );
}

export default ClientLogos
